<template>
  <FormContent type="page" />
</template>

<script>
import FormContent from '@/components/FormContent'

export default {
  name: 'Pages',
  components: {
    FormContent
  }
}
</script>
