<template>
	<div>
      <b-card id="container-select-image">
       <b-card-title><a href="javascript:void(0);" v-b-toggle.collapse-header>{{ title }}</a></b-card-title>
       <b-card-sub-title>Change the featured header images for this page here.</b-card-sub-title>
       <b-collapse id="collapse-header" class="mt-2">
         <h3>Header Image Pool</h3>
         <p class="help">
           Drag and drop items from this <b>Header Image Pool</b> to the <b>Header Images</b> section below.
         </p>
          <div style="width: 100%; overfow: auto;">
            <draggable class="p-2 border" :list="imageOptions" group="images-header" @change="log">
              <b-img v-for="(element, index) in imageOptions" thumbnail class="images-featured m-1" :src="element.src" :alt="element.alt" :key="index"></b-img>
            </draggable>
          </div>
          
          <h3 class="mt-3">Header Images</h3>
          <p class="help">
            Drag and drop items to the desired order.
          </p>
          <div>
            <draggable style="min-height: 100px;" class="p-2 border" :list="imagesSelected" group="images-header" @change="log">
            <div v-for="(element, index) in imagesSelected" class="clearfix" style="display: inline-block;" :key="index">
              <div class="float-left">
                <b-img class="images-pool m-1" thumbnail :src="element.src" :alt="element.alt"></b-img>
                <b-form-input v-model="element.caption" size="lg" placeholder="Caption (optional)" trim></b-form-input>
                <b-form-input v-model="element.text" placeholder="Text (optional)" trim></b-form-input><hr />
                <b-form-input v-model="element.link" placeholder="Link (optional)" trim></b-form-input>
                <b-form-input v-model="element.linkText" placeholder="Link Text (optional)" :disabled="!element.link" trim></b-form-input>
              </div>
            </div>
          </draggable>
          </div>
       </b-collapse>
    </b-card>
	</div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'headerImageSelect',
  components: { Draggable },
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    selected: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: () => ({
    title: 'Header Image Options',
    imagesSelected: [],
    imageOptions: []
  }),
  watch: {
    value (val) {
      this.imagesSelected = val
    },
    imagesSelected (val) {
      this.$emit('input', val)
    },
    options (val) {
      this.imageOptions = val
    },
    imageOptions (val) {
      this.imagesSelected = this.value
    }
  },
  mounted () {
    this.imageOptions = this.options
  },
  methods: {
    add (val) {
      this.list.push(val)
    },
    replace (val) {
      this.list = val
    },
    clone (el) {
      return {
        name: el.name + " cloned"
      };
    },
    log (evt) {
      // console.log(evt)
    }
  }
}
</script>

<style lang="scss" scoped>
#container-select-image {
  width: 100%
}
img.images-featured {
  width: 200px;
}
img.images-pool {
  width: 300px;
}
</style>
